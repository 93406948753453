<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <v-form ref="form">
      <v-card>
        <edit-header :dialog-title="dialogTitle" />

        <v-card-text>
          <validation-observer ref="observer">
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <validation-provider v-slot="{ errors }" name="First Name" rules="required|max:20">
                    <v-text-field
                      v-model.trim="item.firstName"
                      label="First Name"
                      prepend-icon="mdi-account"
                      :error-messages="errors"
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="6">
                  <validation-provider v-slot="{ errors }" name="Last Name" rules="required|max:20">
                    <v-text-field
                      v-model.trim="item.lastName"
                      label="Last Name"
                      prepend-icon="mdi-account-multiple"
                      :error-messages="errors"
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <!-- birthday picker -->
                  <!-- <birthday-picker :date.sync="item.birthday" label="Birthday" :not-empty="true" /> -->
                  <validation-provider v-slot="{ errors }" name="Birthday" rules="required">
                    <m-birthday-picker v-model="item.birthday" label="Birthday" :error-message="errors" />
                  </validation-provider>
                  <!--  -->
                </v-col>

                <v-col cols="12" md="6">
                  <validation-provider v-slot="{ errors }" name="Credentials" rules="max:20">
                    <v-text-field
                      v-model.trim="item.credential"
                      label="Credentials"
                      prepend-icon="mdi-card-account-details"
                      :error-messages="errors"
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="12" md="6">
                  <!-- Dispatchers selector -->
                  <data-selector
                    label="Dispatcher"
                    database-module="dispatchers"
                    getter="dispatchers"
                    item-text="fullName"
                    item-value="id"
                    :not-empty="false"
                    :data.sync="item.dispatcherId"
                  />
                  <!--  -->
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-container>
          </validation-observer>
        </v-card-text>
        <v-divider></v-divider>
        <edit-actions @exit="exit" @submit="onSubmit" />
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import crudEdit from '../../mixins/crudEdit'
// import BirthdayPicker from './_BirthdayPicker.vue'
import DataSelector from './_DataSelector.vue'
import EditHeader from './_CommonEditHeader.vue'
import EditActions from './_CommonEditActions.vue'

import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { max } from 'vee-validate/dist/rules'
import MBirthdayPicker from '../MBirthdayPicker.vue'

extend('max', {
  ...max,
  message: '{_field_} must not exceed {length} characters.',
})

export default {
  components: {
    // BirthdayPicker,
    DataSelector,
    EditHeader,
    EditActions,
    ValidationObserver,
    ValidationProvider,
    MBirthdayPicker,
  },
  mixins: [crudEdit],
  data() {
    return {
      defaultItem: {
        id: '',
        firstName: '',
        lastName: '',
        birthday: '',
        credential: '',
        dispatcherId: '',
      },
    }
  },
  methods: {
    submitFormatter(item) {
      if (item.birthday) {
        item.birthday = new Date(item.birthday).toISOString()
      }
    },
    updateFormatter(item) {
      if (item.birthday) {
        item.birthday = item.birthday.substr(0, 10)
      }
    },
  },
}
</script>
